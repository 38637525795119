<template>
    <div id="invoice-layout" class="">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'invoice-layout',
  components: {
    //   
  },
  data () {
    return {
      // 
    }
  },
  created (){ 
  },
  mounted () {
    // 
  },
  updated () {
    // 
  }
}
</script>

<style scoped>

</style>
